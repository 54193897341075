import { toStatelessImmutable } from '@/utils/object-utils';
import { DTO, Nullable } from '@/type-utils';
import Model from '../Model';
import type {
  IPlacement,
  IHeaderCategory,
  INavigationContent,
  IContent
} from '.';
import CMSPlacementTypes from './CMSPlacementTypes';
import ContentModel from './ContentModel';

/** @inheritdoc */
export default class NavigationContentModel
  extends Model<DTO<INavigationContent>>
  implements INavigationContent
{
  /** @inheritDoc */
  public readonly headerCategories: Array<IHeaderCategory>;

  /** @inheritDoc */
  public readonly globalContent: IContent;

  /** Header banner placement. */
  public readonly headerBanner: Nullable<IPlacement>;

  /** Footer banner placement. */
  public readonly footerBanner: Nullable<IPlacement>;

  /**
   * Constructs the NavigatonContentModel from a generic NavigationContent object.
   * @param data - Object holding the navigation categories and some global content as models.
   */
  public constructor(data: DTO<INavigationContent>) {
    super(data);
    /** Transform the category links coming from CMS to link to the category page. */
    this.headerCategories = data.headerCategories.map(
      (category) => {
        return {
          ...category,
          href: `/c${category.href}`,
        };
      });
    this.globalContent = ContentModel.from(data.globalContent);

    this.headerBanner = (this.globalContent as ContentModel).getPlacement(
      CMSPlacementTypes.MarketingBanner
    );
    this.footerBanner = (this.globalContent as ContentModel).getPlacement(
      CMSPlacementTypes.FooterBanner
    );
  }

  /** @inheritDoc */
  public toDTO(): DTO<INavigationContent> {
    const { headerCategories, globalContent } = this;
    return toStatelessImmutable<Partial<DTO<INavigationContent>>>({
      headerCategories,
      globalContent
    }) as DTO<INavigationContent>;
  }
}
