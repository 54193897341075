'use client';

import {
  FunctionComponent,
  MutableRefObject,
  Suspense,
  useRef,
  useState
} from 'react';

import {
  NavigationContentModel,
  type INavigationContent
} from '@/services/models/Content';
import ConfigurationService from '@/services/isomorphic/ConfigurationService';
import { useLocale } from '@/react/hooks/useLocale';
import { MarketingBanner } from '@/react/components/cms-content';
import { POSStoreBanner } from '@/react/components/endless-aisle/POSStoreBanner';
import { getComponents } from '@/react/components/cms-content/utils/getComponent';
import { ExchangeBanner } from '@/react/components/exchanges/ExchangeBanner';
import { useReturnsFlowState } from '@/react/components/returns/useReturnsFlowState';
import type { Nullable } from '@/type-utils';

import S from './styles.base.module.scss';

export interface IHeaderBannersProps {
  /**
   * The navigation content that represents the banner.
   */
  navigationContent: Nullable<INavigationContent>;
}

/**
 * Renders the possible header banners and manages their state.
 */
export const HeaderBanners: FunctionComponent<IHeaderBannersProps> = ({
  navigationContent = null
}) => {
  const { language, country } = useLocale();

  const returnsFlowState = useReturnsFlowState();

  const headerConfig = ConfigurationService.getConfig(
    'header',
    language,
    country
  );

  const allowBannerScrollCollapse = headerConfig.getSetting(
    'allowBannerScrollCollapse'
  ).value;

  const navigationContentModel = navigationContent
    ? NavigationContentModel.from(navigationContent)
    : null;
  const bannerItem = navigationContentModel
    ? navigationContentModel.headerBanner?.items[0]
    : null;
  const bannerRef = useRef<HTMLDivElement>();

  const [_, setIsBannerOpen] = useState<boolean>(false);

  const marketingBannerNode = bannerItem && (
    <div
      className={S.bannerContainer}
      ref={bannerRef as MutableRefObject<HTMLDivElement>}
    >
      <MarketingBanner
        allowBannerScrollCollapse={allowBannerScrollCollapse}
        contentResolver={getComponents}
        item={bannerItem}
        setIsBannerOpen={setIsBannerOpen}
      />
    </div>
  );

  const exchangeBannerNode = returnsFlowState?.isExchangeModeActive && (
    <ExchangeBanner flowState={returnsFlowState} />
  );

  return (
    <>
      <Suspense>{marketingBannerNode}</Suspense>
      <Suspense>{exchangeBannerNode}</Suspense>
      <Suspense>
        <POSStoreBanner />
      </Suspense>
    </>
  );
};
