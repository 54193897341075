'use client';

import { Suspense, type FC } from 'react';
import UserButton from '@/react/components/navigation/Header/components/UserButton';
import { MiniCartButton } from '@/react/components/cart/MiniCartButton';
import type { INavigationContent } from '@/services/models/Content';
import type { Nullable } from '@/type-utils';
import SkeletonLoader from '@/react/components/utility/SkeletonLoader';
import Search from '../../../../Search';
import { Desktop as DesktopNavbar } from '../..';
import { HeaderLogo } from '../../../../HeaderLogo';
import type { INavbarProps } from '../../../INavbarProps';

export interface IDesktopHeaderProps {
  /**
   * The navigation content that represents the mobile header.
   */
  navigationContent: Nullable<INavigationContent>;
  /**
   * Is search enabled on this component.
   */
  isSearchEnabled: boolean;

  /** Show the user button. */
  showUtilityLinks: boolean;

  /**
   * The navbar props that include configuration for the desktop and mobile navs.
   */
  navbarProps?: INavbarProps;
}

/**
 * The desktop header that sits within the header.
 */
export const DesktopHeader: FC<IDesktopHeaderProps> = ({
  navigationContent,
  isSearchEnabled,
  showUtilityLinks,
  navbarProps
}) => {
  return (
    <>
      <HeaderLogo />
      <DesktopNavbar
        {...navbarProps?.desktop}
        navigationContent={navigationContent}
      />
      {isSearchEnabled && <Search />}
      {!showUtilityLinks && (
        <Suspense fallback={<SkeletonLoader />}>
          <UserButton />
        </Suspense>
      )}
      <Suspense fallback={<SkeletonLoader />}>
        <MiniCartButton />
      </Suspense>
    </>
  );
};
